<div class="sidebar-container">
    <div *ngFor="let item of sidebarItems" class="sidebar-items">
        <app-sidebar-item [item]="item" (click)="onItemSelect(item)"></app-sidebar-item>
    </div>
</div>
<p-sidebar [(visible)]="sidebarVisible" styleClass="w-30rem">
    <div *ngFor="let item of sidebarItems" class="sidebar-items">
        <app-sidebar-item [item]="item" (click)="onItemSelect(item); onSidebarSelect(item)"></app-sidebar-item>
    </div>
</p-sidebar>
<div class="sidebar-button">
    <p-button (click)="showSidebar()" icon="pi pi-bars"></p-button>
    <div class="userInfoSidebar">
      <span class="router-user-info">
        <app-user-info [name]="name" [description]="roles"></app-user-info>
    </span>
    </div>
  </div>
