<div class="header">
     <span class="title">Client has uploaded a file</span>
     <i class="fa-solid fa-x" (click)="close()"></i>
</div>
<div class="content">
    <span class="notification">To view the file, 
        <span class="link" (click)="openFileInNewTab()">click here to view.</span>
    </span>
    <span class="notification">To download the file, <span class="link" (click)="downloadScannedFile()">
        click here.</span>
    </span>
</div>