import { Injectable, Injector, OnDestroy, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { WebrtcService } from 'shared-services';
import { StateHub, StateHubSignalRService } from 'shared-services';
import { MobileHub, MobileHubSignalRService } from '../signalr/mobile-hub-signalr.service';

@Injectable({
  providedIn: 'root',
})
export class PingWebSocketService implements OnDestroy {
  interval: NodeJS.Timeout = null;
  wasDisconnected: boolean = false;
  reconnectTries: number = 0;

  private running: boolean;

  //for mobileHub
  mobileHubInterval: NodeJS.Timeout = null;
  wasDisconnectedFromMobileHub: boolean = false;
  mobileReconnectTries: number = 0;

  constructor(
    private stateHubService: StateHubSignalRService,
    private mobileHubService: MobileHubSignalRService,
    private socket: WebrtcService,
    private injector: Injector) { }

  ngOnDestroy(): void {
    clearInterval(this.interval);
    clearInterval(this.mobileHubInterval);
  }

  public start(milliseconds: number) {
    console.log("starting websockets")
    if (this.interval) {
      clearInterval(this.interval);
    }
    if (this.mobileHubInterval) {
      clearInterval(this.mobileHubInterval);
    }
    if (!this.running) {
      this.stateHubService = this.injector.get(StateHubSignalRService);
      this.socket = this.injector.get(WebrtcService);
      this.running = true;
    }
    this.listen();
    this.listenOnMobileHub();
    var func = () => {
      this.stateHubService.sendMessage(StateHub.Tick, this.socket.peerId);
      if (this.stateHubService.getStatus() == "Disconnected") {
        this.reconnectTries++;
        clearInterval(this.interval);
        this.interval = setInterval(func, milliseconds * this.reconnectTries);
        this.wasDisconnected = true;
      }
      else if (this.stateHubService.getStatus() == "Connected") {
        this.wasDisconnected = false;
        if (!environment.production) {
          console.info('client tick');
        }
        clearInterval(this.interval);
        this.reconnectTries = 0;
        this.interval = setInterval(func, milliseconds);
      }
      console.log("📱📱📱📱📱📱mobile tick");
      this.mobileHubService.sendMessage(MobileHub.Tick, this.socket.peerId);
      if (this.mobileHubService.getStatus() == "Disconnected") {
        console.log("📱 disconnected 🔴")
        this.mobileReconnectTries++;
        clearInterval(this.mobileHubInterval);
        this.mobileHubInterval = setInterval(func, milliseconds * this.mobileReconnectTries);
        this.wasDisconnectedFromMobileHub = true;
      }
      else if (this.mobileHubService.getStatus() == "Connected") {
        console.log("📱 connected 🟢")
        this.wasDisconnectedFromMobileHub = false;
        clearInterval(this.mobileHubInterval);
        this.mobileReconnectTries = 0;
        this.mobileHubInterval = setInterval(func, milliseconds);
      }
    };

    if (!this.wasDisconnected) {
      this.interval = setInterval(func, milliseconds);
    }
    if (!this.wasDisconnectedFromMobileHub) {
      this.mobileHubInterval = setInterval(func, milliseconds);
    }

  }

  public stop() {
    clearInterval(this.interval);
    this.running = false;
  }

  private listen() {
    this.stateHubService.listenOn(StateHub.Tock, () => {
      if (!environment.production) {
        console.info('server tock');
      }
    });
  }
  private listenOnMobileHub() {
    this.mobileHubService.listenOn(MobileHub.Tock, () => {
      if (!environment.production) {
        console.info('server tock from mobileHub');
      }
    });
  }
}
