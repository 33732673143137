<span class="container">
    <span class="incoming-call-title" mat-dialog-title>Do you want to continue?</span>
    <span class="call-info">
      <span class="text-center">Time Remain: {{minutesRemaining}}:{{timeRemaining}}</span>
    </span>
    <div mat-dialog-actions class="action-buttons">
      <button class="cancel-button" (click)="continue()" mat-flat-button mat-dialog-close="false">
        <span>Continue</span>
      </button>
      <button class="send-button" mat-flat-button mat-dialog-close="true" (click)="logout()">
        <span>Log Out</span>
      </button>
    </div>
  </span>