import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { LoggingService, LoggingApiService, AuthenticatedUserService } from 'shared-services';
@Component({
  selector: 'app-user-idle-dialog',
  templateUrl: './user-idle-dialog.component.html',
  styleUrls: ['./user-idle-dialog.component.scss']
})
export class UserIdleDialogComponent implements OnInit {
  public timeRemaining: number = 0;
  public minutesRemaining: number = 0;
  public minutes: number = 0;
  private timeout: any = null;
  constructor(
    private router: Router,
    private authService: AuthService,
    private loggers: LoggingService,
    private logger: LoggingApiService,
    private authenticatedUserService: AuthenticatedUserService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.minutes = data.minutes;
  }

  ngOnInit(): void {

    this.secondTimer();
  }

  continue(): void {
    try {
      clearTimeout(this.timeout);
      this.timeout
      localStorage.setItem("isTimerStarted", "false");
    }
    catch (error) {
      this.logger.logError(error);
    }

  }

  logout(): void {
    try {
      localStorage.removeItem('loggedIn');
      this.authService.signOut();
      this.authenticatedUserService.logout().subscribe();
    }
    catch (error) {
      this.logger.logError(error);
    }
  }

  secondTimer() {
    try {
      let remainingSeconds = this.minutes * 60;
      this.timeout = setInterval(() => {
        if (remainingSeconds == 0) {
          this.logout();
          clearInterval(this.timeout)
        }
        else {
          remainingSeconds--;
        }
        var mins = (remainingSeconds / 60);
        this.minutesRemaining = Math.floor(mins);
        this.timeRemaining = (remainingSeconds - (this.minutesRemaining * 60));

      }, 1000)
    }
    catch
    {

    }

  }

}
