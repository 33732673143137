import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SidebarItem } from 'shared-models';
@Component({
  selector: 'app-sidebar-item',
  templateUrl: './sidebar-item.component.html',
  styleUrls: ['./sidebar-item.component.scss']
})
export class SidebarItemComponent implements OnInit {
  @Input() item: SidebarItem;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }
  
  navigateTo(sidebarItem: SidebarItem) {
    this.router.navigate([sidebarItem.route]);
  }
}
