import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { NetworkDetectorService} from 'shared-services';
import { PingWebSocketService } from './core/services/global-services/ping-web-socket.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  title = 'Spaxel Terminal';
  allowWebrtc: boolean = false;
  lockResolver: (value?: unknown) => void;

  constructor(
    private injector: Injector,
    private router: Router,
    private networkDetector: NetworkDetectorService,
  ) {
    this.lockResolver = () => { };

    if (navigator?.locks?.request) {
      const promise = new Promise((res) => {
        this.lockResolver = res;
      });

      navigator.locks.request('app_lock', { mode: 'shared' }, () => {
        return promise;
      });
    }

    this.networkDetector.start({ url: 'https://peerjs-terminal.spaxel.com' });

    // we need to do this so that the cacheviewer page does not
    // interfere with our signalr or peerjs connections...
    // if removed this will conflict with the connection ids when shown in another tab.
    this.router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if (this.allowWebsockets(event.url)) {
          this.injector.get(PingWebSocketService).start(15000);
          this.allowWebrtc = true;
        } else {
          this.injector.get(PingWebSocketService).stop();
          this.allowWebrtc = false;
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.lockResolver();
  }

  allowWebsockets(path: string): boolean {
    return (
      path != "/" &&
      !path.includes('/router') &&
      !path.includes('/logout') &&
      !path.includes('/cacheviewer') &&
      !path.includes('/thankyou') &&
      !path.includes('/authentication') &&
      !path.includes('/guest/guest-login')
    );
  }

  shouldActivateUser(path: string): boolean {
    return this.allowWebsockets(path) && !path.includes('/video-stream');
  }
}
