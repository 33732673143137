<div class="status-container">
    <span [ngSwitch]="webrtc.connectionState | async" title="{{ webrtc.connectionState | async }}">
      <i *ngSwitchCase="'Connected'" class="fa-solid fa-signal connected"></i>
      <i *ngSwitchCase="'Reconnecting'" class="fa-solid fa-signal reconnecting"></i>
      <i *ngSwitchDefault class="fa-solid fa-signal disconnected"></i>
    </span>
    <span class="version-info">{{webAppVerison}}</span>
    <span *ngIf="!networkDetector.online">
      <i class="fa-solid fa-triangle-exclamation offline-warning"></i>
    </span>
  </div>
  