import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { last, map, mergeMap } from 'rxjs/operators';
import { CallRescheduleDTO, UnitAddressesDTO, AddressDTO} from 'shared-models';
import { ApiRequestsService } from 'shared-services';

export abstract class AppCache {
  public abstract get<T>(key: string): T;
  public abstract set<T>(key: string, item: T): void;
  public abstract update<T>(key: string, item: T): T;
  public abstract delete(key: string);
}

@Injectable()
export class LocalStorageAppCache extends AppCache {
  prefix = 'app.cache.';

  get<T>(key: string): T {
    key = this.prefix + key;

    if (!Object.keys(localStorage).includes(key)) {
      return undefined;
    }

    try {
      return JSON.parse(localStorage[key]) as T;
    } catch (err: unknown) {
      throw Error(err.toString() || 'App cache failed to retrieve key ' + key);
    }
  }

  set<T>(key: string, item: T) {
    key = this.prefix + key;
    localStorage[key] = JSON.stringify(item);
  }

  update<T>(key: string, item: T) {
    try {
      key = this.prefix + key;
      let cache = localStorage[key] || '{}';
      cache = JSON.parse(cache);
      cache = {
        ...cache,
        ...item,
      };
      localStorage[key] = JSON.stringify(cache);
      return cache as T;
    } catch (err: unknown) {
      throw Error(err.toString() || 'App cache failed to update key ' + key);
    }
  }

  delete(key: string) {
    key = this.prefix + key;
    localStorage.removeItem(key);
  }
}

@Injectable()
export class InMemoryAppCache extends AppCache {
  cache: any = {};

  get<T>(key: string): T {
    if (!Object.keys(this.cache).includes(key)) {
      return undefined;
    }

    try {
      return <T>this.cache[key];
    } catch (err: unknown) {
      throw Error(err.toString() || 'App cache failed to retrieve key ' + key);
    }
  }

  set<T>(key: string, item: T) {
    this.cache[key] = item;
  }

  update<T>(key: string, item: T) {
    if (!Object.keys(this.cache).includes(key)) {
      this.cache[key] = {};
    }

    return (this.cache[key] = {
      ...this.cache[key],
      ...item,
    });
  }

  delete(key: string) {
    delete this.cache[key];
  }
}

export interface AddressCache { [address: string]: UnitAddressesDTO[] }

@Injectable({ providedIn: 'root' })
export class AppCacheService {
  constructor(private cache: AppCache,
    private api: ApiRequestsService) { }

  private callbacksKey = "callbacks";

  getCallbacks(): CallRescheduleDTO[] {
    var callbacks = this.cache.get<CallRescheduleDTO[]>(this.callbacksKey);
    if (!callbacks) {
      return [];
    }
    return callbacks;
  }

  addCallback(item: CallRescheduleDTO) {
    var items = this.getCallbacks();

    items.push(item);

    this.cache.set<CallRescheduleDTO[]>(this.callbacksKey, items);
  }

  clearCallbacks() {
    this.cache.delete(this.callbacksKey);
  }

  private addressesKey = 'addresses';

  cacheAddresses(): Promise<AddressCache> {
    return new Promise(resolve => {
      this.api.getAddresses().subscribe(response => {
        if (response?.data?.length) {
          this.cache.set<AddressDTO[]>('addresses_', response.data)
          of(...response.data).pipe(
            mergeMap((item => {
              return this.api.getUnitNumbers(item.street).pipe(map(units => {
                return this.updateAddress({ [item.street]: units });
              }))
            })),
            last())
            .subscribe(resolve);
        }
      });
    });
  }


  cacheAllAddresses(): Promise<AddressDTO[]> {
    return new Promise(resolve => {
      this.api.getAddresses().subscribe(response => {
        if (response?.data?.length) {
          this.cache.set<AddressDTO[]>('addresses_', response.data)
          return resolve(response.data)
        }
      });
    });
  }


  async getAllAddresses(): Promise<AddressDTO[]> {
    var cached = this.cache.get<AddressDTO[]>('addresses_');
    if (cached === undefined) {
      return await this.cacheAllAddresses();
    }
    return cached;
  }


  async getAddresses(): Promise<AddressCache> {
    var cached = this.cache.get<AddressCache>(this.addressesKey);
    if (cached === undefined) {
      return await this.cacheAddresses();
    }

    return cached;
  }

  updateAddress(units: AddressCache): AddressCache {
    return this.cache.update<AddressCache>(
      this.addressesKey,
      units
    );
  }
}