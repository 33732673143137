import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { WebrtcService, NetworkDetectorService, AuthenticatedUserService } from 'shared-services';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-webrtc',
  templateUrl: './webrtc.component.html',
  styleUrls: ['./webrtc.component.scss'],
})
export class WebrtcComponent implements OnInit, OnDestroy {
  @Input('disabled') disabled = false;
  @Input('server') server = 'peerjs-terminal.spaxel.com';
  destroyed$ = new Subject<void>();
  webAppVerison: string;

  constructor(
    public networkDetector: NetworkDetectorService,
    public webrtc: WebrtcService,
    private authenticatedUserService: AuthenticatedUserService,
  ) { }

  async ngOnDestroy(): Promise<void> {
    await this.webrtc.serverClose(false);
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  async ngOnInit(): Promise<void> {
    //webVersionAPP
    const APP_VERSION = require('../../../../package.json').version;
    if (APP_VERSION) {
      (window as any).APP_VERSION = APP_VERSION;
      this.webAppVerison = APP_VERSION;
    }
    //
    window.addEventListener('unload', async (_) => {
      await this.webrtc.serverClose(false);
      await this.webrtc.delay(100);
    });

    if (this.disabled) { return; }
    var user = await this.authenticatedUserService.getClaims();;
    this.webrtc
      .configure({
        id: user.username,
        server: this.server,
        user: `${user.firstName} ${user.lastName}`,
      })
      .serverConnect();

    this.networkDetector.changed$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(async online => {
        if (online) {
          await this.webrtc.serverConnect();
        } else {
          await this.webrtc.serverDisconnect();
        }
      });
  }
}
