import { AgentEnvironment } from "shared-services/lib/environment.interface";

export const environment: AgentEnvironment = {
  production: false,
  apiURL: 'https://dev-terminal.spaxel.com', //http://localhost:5179,
  flaskApiURL: 'https://apiv1.terminal.spaxel.com',
  authority: 'https://spaxel.onelogin.com/oidc/2',
  client_id: '9cffcdb0-f0d9-013a-2d2c-0a2a63599a57175146', //prod'f7d191f0-756c-013a-b913-02c5289f8e3d175146',// test ea437c70-a213-013a-dccb-0abbb659e731175146
  redirect_uri: `${window.location.origin}/agent/authentication`, //http://localhost:4200
  post_login_route: `agent/authentication`,
  post_logout_redirect_uri: `${window.location.origin}`, //http://localhost:4200
  response_type: 'id_token token',
  scope: 'openid profile',
  endSessionMinutes: 10,
  showDialogEndSessionInMinutes: 10,
  ringtoneFileName: 'mi_pure.mp3',
  idleDefaults: {
    // begin idle after X seconds
    after: 5,
    // wait X seconds to timeout
    timeout: 5,
  }
};
