<div class="snackbar-content h-100 w-100">

    <div class="container-fluid h-100 w-100 p-0 m-0">
        <div class="row h-100 w-100 p-0 m-0">
    <div class="col-md-10 h-100 w-100 p-0 m-0">
    <div class="row align-items-center p-0 m-0" style="height: 50%; width: 100%; ">
        <span class="title title-success" *ngIf="notificationDTO.isSuccess == true" >{{notificationDTO.title}}</span>
        <span class="title title-error" *ngIf="notificationDTO.isSuccess == false" >{{notificationDTO.title}}</span>
    </div>  
    <div class="row d-flex align-items-center p-0 m-0" style="height: 50%; width: 100%;">
    <span class="message">{{notificationDTO.message}}</span>
    </div>
    </div>
    <div class="col-md-2 d-flex align-items-center justify-content-center">
        <a (click)="close()">
            <i class="fa-solid fa-xmark"></i>
    </a>
    </div>
        </div>
    
    </div>
    </div>