import { Component, ChangeDetectorRef, Inject, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { CallRescheduleDTO } from 'shared-models';
import { RescheduleCallService } from 'shared-services';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Message } from 'primeng/api';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NetworkDetectorService } from 'shared-services';
import { AppCacheService } from 'src/app/core/services/app-cache/app-cache.service';
import { LoggingService } from 'shared-services';
import { LoggingApiService } from 'shared-services';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-schedule-callback-dialog',
  templateUrl: './schedule-callback-dialog.component.html',
  styleUrls: ['./schedule-callback-dialog.component.scss'],

})
export class ScheduleCallbackDialogComponent implements OnInit, OnDestroy {
  title: 'Schedule a callback';
  description: string = '';
  unit: string = 'Leasing';
  unitId: number = 0;
  displayDialog: boolean = true;
  remark: string;
  phoneNo: string;
  email: string;
  callReschedule: CallRescheduleDTO;
  showErrorMessage: boolean = false;
  errorMessages: Message[] = [];
  idleState = "NOT_STARTED";
  countdown?: string = null;
  isNotUnexpected: boolean = true;

  idleOnStart$: Subscription;
  idleOnEnd$: Subscription;
  idleOnTimeout$: Subscription;
  idleOnWarning$: Subscription;
  afterClosed$: Subscription;

  @Output() close: EventEmitter<any> = new EventEmitter();
  dialogWidth: string = '50vw';

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    private idle: Idle,
    private rescheduleCallsService: RescheduleCallService,
    private networkDetector: NetworkDetectorService,
    private dialogRef: MatDialogRef<ScheduleCallbackDialogComponent>,
    private appCache: AppCacheService,
    private logger: LoggingService,
    private matDialog: MatDialog,
    private loggers: LoggingApiService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.title = data.title;
    this.description = data.description;
    this.isNotUnexpected = data.isNotUnexpected;
    this.unit = data.unit || this.unit;
    this.unitId = data.unitId || this.unitId;

    this.idle.setIdleName("ScheduleCallback");
    this.idle.setIdle(environment.idleDefaults.after);
    this.idle.setTimeout(environment.idleDefaults.timeout);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

  }

  ngOnDestroy(): void {
    this.idle.stop();
    this.idleOnStart$?.unsubscribe();
    this.idleOnEnd$?.unsubscribe();
    this.idleOnTimeout$?.unsubscribe();
    this.idleOnWarning$?.unsubscribe();
    this.afterClosed$?.unsubscribe();
  }

  async ngOnInit() {

    this.afterClosed$ = this.dialogRef.afterClosed()
      .subscribe(() => { this.close.emit(); });
    this.subscribeIdle();
    this.resetIdle();
    this.afterClosed$ = this.dialogRef.afterClosed()
      .subscribe(() => { this.close.emit(); });

  }

  subscribeIdle() {
    this.idleOnStart$ = this.idle.onIdleStart.subscribe(() => {
      this.idleState = "IDLE";
    });

    this.idleOnEnd$ = this.idle.onIdleEnd.subscribe(() => {
      this.idleState = "NOT_IDLE";
      this.countdown = null;
      this.cd.detectChanges();
    });

    this.idleOnTimeout$ = this.idle.onTimeout.subscribe(() => {
      this.idleState = "TIMED_OUT";
      this.dialogRef.close();
    });

    this.idleOnWarning$ = this.idle.onTimeoutWarning.subscribe(seconds => {
      this.countdown = `${seconds}`;
    });
  }



  makeAnotherCall(): void {

    this.router.navigate(['client/chooseproperty']);
  }

  showRescheduleCallbackDialog(): void {
    this.dialogRef.close();
    var errorType = 'Error.NoAgentsAvailable';
    this.matDialog.open(ScheduleCallbackDialogComponent, {
      disableClose: false,
      data: {
        title: 'Terminal.ScheduleCallback',
        description: errorType,
        isNotUnexpected: true
      },
      panelClass: 'schedule-callback-dialog',
    });
  }

  resetIdle() {
    // we'll call this method when we want to start/reset the idle process
    // reset any component state and be sure to call idle.watch()
    this.idle.watch();
    this.idleState = "NOT_IDLE";
    this.countdown = null;
  }


  closeDialogForMobile() {
    this.dialogRef.close();
  }

 
}
