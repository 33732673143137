import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RoomData } from 'shared-models';

@Component({
  selector: 'app-agent-call-dialog',
  templateUrl: './agent-call-dialog.component.html',
  styleUrls: ['./agent-call-dialog.component.scss']
})
export class AgentCallDialogComponent implements OnInit {
  property: string = "";
  unit: string = "";
  tenant_image = "";
  title: string = "Incoming call";

  constructor(@Inject(MAT_DIALOG_DATA) public data: RoomData) {
    this.property = data.Address;
    if (data.TenantImage) {
      this.tenant_image = `data:image/png;base64,${data.TenantImage}`;
    }
    this.unit = data.UnitName || "Leasing";
    this.title = "Incoming call";
  }

  ngOnInit(): void {
  }
}
