<span class="container">


  <span class="incoming-call-title" mat-dialog-title>
    <i class="fa-solid fa-mobile-retro"></i>
    {{title}}</span>

  <span class="call-info">
    <dl>
      <dt>User:</dt>
      <dd class="value">
        <div>{{address}}</div>
      </dd>
      <dt>Unit:</dt>
      <dd class="value">
        <div>{{unit}}</div>
      </dd>
      <dt>Calling:</dt>
      <dd class="value">
        <div>{{tenantName}}</div>
      </dd>
    </dl>
  </span>

  <p-divider></p-divider>

  <div mat-dialog-actions class="action-buttons">
    <button class="cancel-button" mat-flat-button mat-dialog-close>
      <i class="fa-solid fa-phone-slash"></i>
      <span>Decline</span>
    </button>
    <button class="send-button" mat-flat-button mat-dialog-close="accepted">
      <i class="fa-solid fa-phone"></i>
      <span>Accept</span>
    </button>
  </div>
</span>