import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api/menuitem';
import { UserDTO } from 'shared-models';
import { AuthenticatedUserService } from 'shared-services';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  user: UserDTO;
  items: MenuItem[] = [
    { label: 'Log Out', icon: 'pi pi-fw pi-sign-out', command: () => this.signOut() },
  ];
  name: string;
  roles: string;
  isAdmin: boolean;
  adminNavigationItems: SelectButtonItem[] = [{ name: 'Admin', code: 'admin' }, { name: 'Agent', code: 'agent' }]
  pageNavigationItem: SelectButtonItem;

  constructor(
    private authenticatedUserService: AuthenticatedUserService,
    private router: Router,
    private ngZone: NgZone
  ) { }

  async ngOnInit(): Promise<void> {
    await this.setCurrentUserData();
    this.setIsAdmin();
    this.getCurrentModuleName();
    this.selectHeaderOptionFromRoute();
  }

  private selectHeaderOptionFromRoute(): void {
    this.adminNavigationItems.forEach(item => {
      if (this.isRoutedTo(item.code)) this.pageNavigationItem = item;
    });
  }

  private isRoutedTo(path: string): boolean {
    return window.location.href.includes(`/${path}`);
  }

  private setIsAdmin(): void {
    this.isAdmin = this.roles.includes('Admin');
  }

  private async setCurrentUserData(): Promise<void> {
    const userClaims = await this.authenticatedUserService.getClaims();
    this.name = `${userClaims.firstName} ${userClaims.lastName}`;
    if (Array.isArray(userClaims.roles))
      this.roles = userClaims.roles.join(', ')
    else
      this.roles = userClaims.roles
  }

  private signOut(): void {
    this.authenticatedUserService.logout().subscribe(_ => _);
    this.router.navigate(["agent/thankyou"]);
  }

  private getCurrentModuleName(): string {
    return this.router.url.split('/')[1];
  }
}

export class SelectButtonItem {
  name: string;
  code: string;
}