import { Component, Input } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Router } from '@angular/router';
import { AuthenticatedUserService } from 'shared-services';
@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent {
  @Input() name: string;
  @Input() description: string;

  items: MenuItem[] = [
    { label: 'Log Out', icon: 'pi pi-fw pi-sign-out', command: () => this.signOut() },
  ];

  constructor(private router: Router,
    private authenticatedUserService: AuthenticatedUserService) { }

  private signOut(): void {
    this.authenticatedUserService.logout().subscribe();
    this.router.navigate(["agent/thankyou"]);
  }

  public setInitials(): string {
    return this.name?.split(' ').map(item => item?.[0]).join('');
  }

}
