import { Component, Inject, OnInit, Optional } from '@angular/core';
import { UploadResultDTO } from  'shared-models';
import { saveAs } from 'file-saver';
import { FilesService, ToastService } from 'shared-services';
@Component({
  selector: 'app-file-uploaded-toaster',
  templateUrl: './file-uploaded-toaster.component.html',
  styleUrls: ['./file-uploaded-toaster.component.scss']
})
export class FileUploadedToasterComponent implements OnInit {

  constructor(
    @Optional() @Inject('UploadResultDTO') public uploadResult: UploadResultDTO,
    private fileService: FilesService,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
  }

  openFileInNewTab(): void {
    window.open(this.uploadResult.fileURL, '_blank').focus();
  }
  private  downloadFile(fileName){
    try {
      this.fileService.downloadFile(fileName).subscribe((blob: Blob) => {
        saveAs(blob, fileName);
        })
    } catch (error) {
      console.error(error);
      
    }
   
  }
  downloadScannedFile(): void {
    this.downloadFile(this.uploadResult.folderURL);
  }

  close(): void{
    this.toastService.close();
  }
}
