<div class="header-container">
    <div class="spaxel-logo ">
        <img src="../../../assets/Spaxel_logo_black.png" alt="">
    </div>
    <div class="right">
        <span class="router-user-info">
            <app-user-info [name]="name" [description]="roles"></app-user-info>
        </span>
    </div>
</div>
