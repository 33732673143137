import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserDTO } from 'shared-models';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private http: HttpClient) {
  }

  isLoggedIn(): boolean {

    const token = localStorage.getItem('loggedIn');
    return !!token;
  }

  startAuthentication(): void {
    this.oidcSecurityService.authorize();
  }

  authenticateAgent(profile: UserDTO): Observable<void> {
    let url = environment.apiURL + '/api/Accounts/AuthenticateAgent';
    return this.http.post<any>(url, profile)
      .pipe(map((result: any) => {
        localStorage.setItem('loggedIn', "true");
      }))
  }

  signOut() {
    localStorage.removeItem('loggedIn');
    this.oidcSecurityService.logoff();
  }


  checkIfAuthenticated(): Promise<boolean> {
    return new Promise(resolve => {
      this.oidcSecurityService.checkAuth()
        .subscribe((loginResponse) => {
          const { isAuthenticated } =
            loginResponse;
          if (!isAuthenticated) {
            this.oidcSecurityService.authorize();
            resolve(false);
          }
          resolve(true);
        });

    });
  }
}
